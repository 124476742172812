/**
 * @generated SignedSource<<7bd008a3d0b3c6790a7e554be2675535>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Category = "INVESTMENT_BANKING" | "TECH";
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$variables = {
  category: Category;
  isAnswerBasedAcceptance: boolean;
  userID?: string | null | undefined;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};
export type LayoutTopBarProblemUnitViewProgressRefetchQuery = {
  response: LayoutTopBarProblemUnitViewProgressRefetchQuery$data;
  variables: LayoutTopBarProblemUnitViewProgressRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "category"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAnswerBasedAcceptance"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = {
  "kind": "Variable",
  "name": "category",
  "variableName": "category"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "equals",
        "variableName": "category"
      }
    ],
    "kind": "ObjectValue",
    "name": "category"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v4 = {
  "fields": (v2/*: any*/),
  "kind": "ObjectValue",
  "name": "problem"
},
v5 = [
  {
    "fields": [
      (v4/*: any*/),
      {
        "kind": "Literal",
        "name": "score",
        "value": {
          "gte": 7
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v6 = [
  {
    "fields": [
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "isAnswerBasedAcceptance",
            "variableName": "isAnswerBasedAcceptance"
          },
          {
            "kind": "Variable",
            "name": "userID",
            "variableName": "userID"
          }
        ],
        "kind": "FragmentSpread",
        "name": "LayoutTopBarProblemUnitViewProgress_content"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userID"
          }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "fetch__user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v1/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "completed_problems_count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": (v2/*: any*/),
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "ProblemConnection",
        "kind": "LinkedField",
        "name": "multifetch__problem",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "condition": "isAnswerBasedAcceptance",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "answerBasedCorrect",
            "args": (v5/*: any*/),
            "concreteType": "AnswerConnection",
            "kind": "LinkedField",
            "name": "multifetch__answer",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": "answerBasedTotal",
            "args": (v6/*: any*/),
            "concreteType": "AnswerConnection",
            "kind": "LinkedField",
            "name": "multifetch__answer",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isAnswerBasedAcceptance",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": "mockInterviewBasedCorrect",
            "args": (v5/*: any*/),
            "concreteType": "MockInterviewConnection",
            "kind": "LinkedField",
            "name": "multifetch__mock_interview",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": "mockInterviewBasedTotal",
            "args": (v6/*: any*/),
            "concreteType": "MockInterviewConnection",
            "kind": "LinkedField",
            "name": "multifetch__mock_interview",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "d10ec3fbb167fd55d8dd16d5b07f292f",
    "id": null,
    "metadata": {},
    "name": "LayoutTopBarProblemUnitViewProgressRefetchQuery",
    "operationKind": "query",
    "text": "query LayoutTopBarProblemUnitViewProgressRefetchQuery(\n  $category: Category!\n  $isAnswerBasedAcceptance: Boolean!\n  $userID: ID\n) {\n  ...LayoutTopBarProblemUnitViewProgress_content_4gqVEF\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance_15BdcN on Query {\n  answerBasedCorrect: multifetch__answer(where: {problem: {category: {equals: $category}}, score: {gte: 7}}) {\n    count\n  }\n  answerBasedTotal: multifetch__answer(where: {problem: {category: {equals: $category}}}) {\n    count\n  }\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_content_4gqVEF on Query {\n  fetch__user(id: $userID) {\n    completed_problems_count(category: $category)\n    id\n  }\n  multifetch__problem(where: {category: {equals: $category}}) {\n    count\n  }\n  ...LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance_15BdcN @include(if: $isAnswerBasedAcceptance)\n  ...LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance_15BdcN @skip(if: $isAnswerBasedAcceptance)\n}\n\nfragment LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance_15BdcN on Query {\n  mockInterviewBasedCorrect: multifetch__mock_interview(where: {problem: {category: {equals: $category}}, score: {gte: 7}}) {\n    count\n  }\n  mockInterviewBasedTotal: multifetch__mock_interview(where: {problem: {category: {equals: $category}}}) {\n    count\n  }\n}\n"
  }
};
})();

(node as any).hash = "af703143fe846b6ae3a6144a15c496e6";

export default node;
