/**
 * @generated SignedSource<<4dfb4b3ed2cbd84fe3e7e54d987fe881>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$data = {
  readonly answerBasedCorrect: {
    readonly count: number;
  };
  readonly answerBasedTotal: {
    readonly count: number;
  };
  readonly " $fragmentType": "LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance";
};
export type LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$key = {
  readonly " $data"?: LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "equals",
          "variableName": "category"
        }
      ],
      "kind": "ObjectValue",
      "name": "category"
    }
  ],
  "kind": "ObjectValue",
  "name": "problem"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance",
  "selections": [
    {
      "alias": "answerBasedCorrect",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "score",
              "value": {
                "gte": 7
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "AnswerConnection",
      "kind": "LinkedField",
      "name": "multifetch__answer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "answerBasedTotal",
      "args": [
        {
          "fields": [
            (v0/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "AnswerConnection",
      "kind": "LinkedField",
      "name": "multifetch__answer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "aa1fda94d57bd62eb5a8ea413ea5cd6d";

export default node;
