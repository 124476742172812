/**
 * @generated SignedSource<<df3b6111fe7d7f6774a9c11d6aa12589>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutTopBarProblemUnitViewProgress_content$data = {
  readonly fetch__user: {
    readonly completed_problems_count: number;
  } | null | undefined;
  readonly multifetch__problem: {
    readonly count: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance" | "LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance">;
  readonly " $fragmentType": "LayoutTopBarProblemUnitViewProgress_content";
};
export type LayoutTopBarProblemUnitViewProgress_content$key = {
  readonly " $data"?: LayoutTopBarProblemUnitViewProgress_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_content">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "category",
    "variableName": "category"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isAnswerBasedAcceptance"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userID"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql')
    }
  },
  "name": "LayoutTopBarProblemUnitViewProgress_content",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userID"
        }
      ],
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "fetch__user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "completed_problems_count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "equals",
                  "variableName": "category"
                }
              ],
              "kind": "ObjectValue",
              "name": "category"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "ProblemConnection",
      "kind": "LinkedField",
      "name": "multifetch__problem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "isAnswerBasedAcceptance",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance"
        }
      ]
    },
    {
      "condition": "isAnswerBasedAcceptance",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance"
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "af703143fe846b6ae3a6144a15c496e6";

export default node;
