/**
 * @generated SignedSource<<ab8d2bb1ff29791de3607f1df7720de9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$data = {
  readonly mockInterviewBasedCorrect: {
    readonly count: number;
  };
  readonly mockInterviewBasedTotal: {
    readonly count: number;
  };
  readonly " $fragmentType": "LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance";
};
export type LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$key = {
  readonly " $data"?: LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$data;
  readonly " $fragmentSpreads": FragmentRefs<"LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "equals",
          "variableName": "category"
        }
      ],
      "kind": "ObjectValue",
      "name": "category"
    }
  ],
  "kind": "ObjectValue",
  "name": "problem"
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "category"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance",
  "selections": [
    {
      "alias": "mockInterviewBasedCorrect",
      "args": [
        {
          "fields": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "score",
              "value": {
                "gte": 7
              }
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "MockInterviewConnection",
      "kind": "LinkedField",
      "name": "multifetch__mock_interview",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "mockInterviewBasedTotal",
      "args": [
        {
          "fields": [
            (v0/*: any*/)
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "MockInterviewConnection",
      "kind": "LinkedField",
      "name": "multifetch__mock_interview",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fd6571f04c0a90eebcbbc9508712e808";

export default node;
