"use strict";

import { useLocation } from "react-router-dom";
import { getCategoryFromPathname } from "../../route/RouteUtils";
import { useMemo } from "react";
import { Category } from "../common/__generated__/ProblemLikeIconButtonCreateFavoriteMutation.graphql";
import {
  AccountBalance as AccountBalanceIcon,
  Build as BuildIcon,
} from "@mui/icons-material";

export const CATEGORY_METADATA = {
  INVESTMENT_BANKING: {
    path: "investment_banking",
    title: "Investment Banking",
    Icon: AccountBalanceIcon,
  },
  TECH: {
    path: "product_manager",
    title: "Product Manager",
    Icon: BuildIcon,
  },
};

export default function useCategory(): Category | null {
  const { pathname } = useLocation();

  return useMemo(() => getCategoryFromPathname(pathname), [pathname]);
}
