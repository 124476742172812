"use strict";

import { FC, ReactElement, useContext, useEffect, useMemo } from "react";
import {
  useFragment,
  useLazyLoadQuery,
  useRefetchableFragment,
} from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import {
  Category,
  LayoutTopBarProblemUnitViewProgressQuery,
} from "./__generated__/LayoutTopBarProblemUnitViewProgressQuery.graphql";
import { Skeleton, Stack, Typography } from "@mui/material";
import Suspensable from "../../common/Suspensable";
import { useHover } from "@uidotdev/usehooks";
import useUser from "../../../user/useUser";
import { LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance.graphql";
import { LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance.graphql";
import LayoutContext from "../LayoutContext";
import { LayoutTopBarProblemUnitViewProgressRefetchQuery } from "./__generated__/LayoutTopBarProblemUnitViewProgressRefetchQuery.graphql";
import { LayoutTopBarProblemUnitViewProgress_content$key } from "./__generated__/LayoutTopBarProblemUnitViewProgress_content.graphql";

const LayoutTopBarProblemUnitViewProgressAcceptance: FC<{
  correct: number;
  total: number;
}> = ({ correct, total }): ReactElement => {
  const acceptanceRate = useMemo(
    () => Math.round((correct * 1000) / total) / 1000,
    [correct, total],
  );

  return (
    <>
      <Typography fontWeight="bold">{acceptanceRate * 100}%</Typography>{" "}
      <Typography variant="subtitle2" color="textDisabled">
        Acceptance
      </Typography>
    </>
  );
};

const LayoutTopBarProblemUnitViewProgressAnswerBasedAcceptance: FC<{
  answerBasedAcceptance: LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$key;
}> = ({ answerBasedAcceptance }): ReactElement => {
  const { answerBasedCorrect, answerBasedTotal } =
    useFragment<LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance$key>(
      graphql`
        fragment LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance on Query
        @argumentDefinitions(category: { type: "Category!" }) {
          answerBasedCorrect: multifetch__answer(
            where: {
              problem: { category: { equals: $category } }
              score: { gte: 7 }
            }
          ) {
            count
          }
          answerBasedTotal: multifetch__answer(
            where: { problem: { category: { equals: $category } } }
          ) {
            count
          }
        }
      `,
      answerBasedAcceptance,
    );

  return (
    <LayoutTopBarProblemUnitViewProgressAcceptance
      correct={answerBasedCorrect.count}
      total={answerBasedTotal.count}
    />
  );
};

const LayoutTopBarProblemUnitViewProgressMockInterviewBasedAcceptance: FC<{
  mockInterviewBasedAcceptance: LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$key;
}> = ({ mockInterviewBasedAcceptance }): ReactElement => {
  const { mockInterviewBasedCorrect, mockInterviewBasedTotal } =
    useFragment<LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance$key>(
      graphql`
        fragment LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance on Query
        @argumentDefinitions(category: { type: "Category!" }) {
          mockInterviewBasedCorrect: multifetch__mock_interview(
            where: {
              problem: { category: { equals: $category } }
              score: { gte: 7 }
            }
          ) {
            count
          }
          mockInterviewBasedTotal: multifetch__mock_interview(
            where: { problem: { category: { equals: $category } } }
          ) {
            count
          }
        }
      `,
      mockInterviewBasedAcceptance,
    );

  return (
    <LayoutTopBarProblemUnitViewProgressAcceptance
      correct={mockInterviewBasedCorrect.count}
      total={mockInterviewBasedTotal.count}
    />
  );
};

const LayoutTopBarProblemUnitViewProgressContent: FC<Props> = ({
  category,
}): ReactElement => {
  const { shouldTopBarProgressUpdate, setShouldTopBarProgressUpdate } =
    useContext(LayoutContext);
  const [ref, isHover] = useHover();
  const { user } = useUser();
  const isAnswerBasedAcceptance = useMemo(() => {
    switch (category) {
      case "INVESTMENT_BANKING":
        return true;
      case "TECH":
        return false;
    }
  }, [category]);
  const dataFragment =
    useLazyLoadQuery<LayoutTopBarProblemUnitViewProgressQuery>(
      graphql`
        query LayoutTopBarProblemUnitViewProgressQuery(
          $userID: ID
          $category: Category!
          $isAnswerBasedAcceptance: Boolean!
        ) {
          ...LayoutTopBarProblemUnitViewProgress_content
            @arguments(
              userID: $userID
              category: $category
              isAnswerBasedAcceptance: $isAnswerBasedAcceptance
            )
        }
      `,
      { userID: user?.id, category, isAnswerBasedAcceptance },
    );

  const [data, refetch] = useRefetchableFragment<
    LayoutTopBarProblemUnitViewProgressRefetchQuery,
    LayoutTopBarProblemUnitViewProgress_content$key
  >(
    graphql`
      fragment LayoutTopBarProblemUnitViewProgress_content on Query
      @refetchable(queryName: "LayoutTopBarProblemUnitViewProgressRefetchQuery")
      @argumentDefinitions(
        userID: { type: "ID" }
        category: { type: "Category!" }
        isAnswerBasedAcceptance: { type: "Boolean!" }
      ) {
        fetch__user(id: $userID) {
          completed_problems_count(category: $category)
        }
        multifetch__problem(where: { category: { equals: $category } }) {
          count
        }
        ...LayoutTopBarProblemUnitViewProgress_answerBasedAcceptance
          @arguments(category: $category)
          @include(if: $isAnswerBasedAcceptance)
        ...LayoutTopBarProblemUnitViewProgress_mockInterviewBasedAcceptance
          @arguments(category: $category)
          @skip(if: $isAnswerBasedAcceptance)
      }
    `,
    dataFragment,
  );

  useEffect(() => {
    if (shouldTopBarProgressUpdate) {
      refetch(
        { userID: user?.id, category, isAnswerBasedAcceptance },
        { fetchPolicy: "network-only" },
      );
      setShouldTopBarProgressUpdate(false);
    }
  }, [
    category,
    isAnswerBasedAcceptance,
    refetch,
    setShouldTopBarProgressUpdate,
    shouldTopBarProgressUpdate,
    user?.id,
  ]);

  const {
    fetch__user,
    multifetch__problem: { count: totalProblemCount },
  } = data;

  const completedProblemCount = useMemo(
    () => fetch__user?.completed_problems_count ?? 0,
    [fetch__user?.completed_problems_count],
  );

  return (
    <Stack ref={ref} alignItems="center">
      {isHover ? (
        isAnswerBasedAcceptance ? (
          <LayoutTopBarProblemUnitViewProgressAnswerBasedAcceptance
            answerBasedAcceptance={data}
          />
        ) : (
          <LayoutTopBarProblemUnitViewProgressMockInterviewBasedAcceptance
            mockInterviewBasedAcceptance={data}
          />
        )
      ) : (
        <>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">{completedProblemCount}</Typography>
            <Typography color="textDisabled">/</Typography>
            <Typography color="textDisabled">{totalProblemCount}</Typography>
          </Stack>
          <Typography variant="subtitle2" color="textDisabled">
            Total
          </Typography>
        </>
      )}
    </Stack>
  );
};

interface Props {
  category: Category;
}

const LayoutTopBarProblemUnitViewProgress: FC<Props> = (
  props,
): ReactElement => {
  return (
    <Suspensable
      fallback={
        <Stack alignItems="center">
          <Stack direction="row" gap={1}>
            <Skeleton variant="text" width={40} />
            <Typography color="textDisabled">/</Typography>
            <Skeleton variant="text" width={40} />
          </Stack>
          <Typography variant="subtitle2" color="textDisabled">
            Total
          </Typography>
        </Stack>
      }
    >
      <LayoutTopBarProblemUnitViewProgressContent {...props} />
    </Suspensable>
  );
};

export default LayoutTopBarProblemUnitViewProgress;
