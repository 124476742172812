"use strict";

import { useLocation } from "react-router-dom";
import { getModuleTypeFromPathname } from "../../route/RouteUtils";
import { useMemo } from "react";
import {
  Quiz as QuizIcon,
  School as SchoolIcon,
  SvgIconComponent,
  Tag as TagIcon,
} from "@mui/icons-material";

export enum ModuleType {
  QUESTIONS = "QUESTIONS",
  MOCK_INTERVIEW = "MOCK_INTERVIEW",
  TAGS = "TAGS",
}

export const MODULE_TYPE_METADATA: {
  [key in ModuleType]: { path: string; title: string; Icon: SvgIconComponent };
} = {
  QUESTIONS: {
    path: "",
    title: "Questions",
    Icon: QuizIcon,
  },
  MOCK_INTERVIEW: {
    path: "mock_interview",
    title: "Mock Interview",
    Icon: SchoolIcon,
  },
  TAGS: {
    path: "tags",
    title: "Tags",
    Icon: TagIcon,
  },
};

export default function useModuleType(): ModuleType | null {
  const { pathname } = useLocation();

  return useMemo(() => getModuleTypeFromPathname(pathname), [pathname]);
}
